<template>
  <div>
    <v-skeleton-loader
      v-if="skeleton"
      class="transparent"
      type="table-thead, table-tbody"
    />

    <v-data-table
      v-else
      :headers="headers"
      :items="releases"
      :items-per-page="10"
      :page.sync="page"
      :server-items-length="totalReleases"
      class="v-card v-sheet v-sheet--outlined radius-10 release-table hide-v-data-footer__select"
      mobile-breakpoint="550"
    >
      <!-- <td> -> icons -->
      <template #item.icon="{ item }">
        <div class="d-flex justify-center align-center my-2">
          <AppImage
            :appId="item.id || ''"
            :image="item.image"
            size="40"
            class="d-flex justify-center align-center"
          />
        </div>
      </template>

      <!-- <td> -> platforms -->
      <template #item.platform="{ item }">
        <PlatformIcon
          android
          size="32"
          color="primary"
          v-if="item.platform == 'android'"
        />
        <PlatformIcon
          apple
          size="32"
          color="primary"
          v-if="item.platform == 'ios'"
        />
      </template>

      <!-- <td> -> versions -->
      <template #item.version="{ item }">
        <MaxText
          :text="`v${item.info.version} (${item.info.version_code})`"
          max="20"
        />
      </template>

      <!-- <td> -> packages -->
      <template #item.package="{ item }">
        <MaxText :text="item.info.package" max="26" />
      </template>

      <!-- <td> -> sizes -->
      <template #item.size="{ item }">
        {{ formatBytes(item.info.size) }}
      </template>
      <!-- <td> -> dates -->
      <template #item.date="{ item }">
        <vue-hoverable-date
          v-if="item.archive_at"
          :is-expired="item.status === 3"
          :date="item.archive_at"
        />
        <div v-else>-</div>
      </template>
      <template #item.status="{ item }">
        <span :class="$options.STATUS[item.status].color + '--text'">
          {{ $options.STATUS[item.status].title }}
        </span>
      </template>

      <!-- <td> -> drop down -->
      <template #item.action="{ item }">
        <v-icon class="mr-1" v-if="[4, 5].includes(item.status)"
          >mdi-minus</v-icon
        >
        <action-dropdown v-else>
          <template #list>
            <v-list-item
              @click="$emit('install', item)"
              v-if="item.status == 1"
            >
              <v-icon small>mdi-cellphone-iphone</v-icon>
              Install
            </v-list-item>
            <v-list-item
              @click="onDeleteClick(item)"
              v-if="item.status !== 4 && item.status !== 5"
            >
              <v-icon small>cancel</v-icon>
              Delete
            </v-list-item>
          </template>
        </action-dropdown>
      </template>

      <!-- if no data -->
      <template #no-data>
        <div class="no-data-found mt-6">
          <inline-svg
            class="svg-icon empty_icon"
            src="/media/placeholders/no_apps.svg"
          />
          <p class="mt-4 empty-title font-weight-bold text-dark text-center">
            No active apps
          </p>
          <p class="mt-4 empty-title font-weight-bold text-dark text-center">
            This list is only visible to you but you can share the apps not the
            whole list.
          </p>
        </div>
      </template>
    </v-data-table>
    <Modal v-model="deletePopup">
      <template #message>
        <v-card-title class="font-weight-bold">Delete release</v-card-title>
        <v-card-text class="message">
          <p>Are you sure you want to delete this release?</p>
          <p>There's no undo for this action.</p>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pb-0">
          <v-spacer />
          <v-btn class="main" @click="deletePopup = false">no</v-btn>
          <v-btn
            class="main primary"
            @click="deleteRelease"
            :loading="loading"
            :disabled="loading"
          >
            yes
          </v-btn>
        </v-card-actions>
      </template>
    </Modal>
  </div>
</template>

<script>
import {
  GET_EXPRESS_LIST,
  GET_EXPRESS_RELEASE_TOKEN,
  REMOVE_EXPRESS_RELEASE
} from "@/store/releases/expressRelease.module.js";
import { formatBytes } from "@/core/services/helper.service";
import AppImage from "@/view/components/App/Image.vue";
import { mapGetters, mapMutations } from "vuex";
import socket from "@/store/common/socket-instance";

export default {
  components: {
    AppImage
  },
  STATUS: {
    1: { title: "Active", color: "green" },
    2: { title: "Failed", color: "red" },
    3: { title: "Archived", color: "grey" },
    4: { title: "Uploading", color: "darkGrey" },
    5: { title: "Processing", color: "darkGrey" }
  },
  data() {
    return {
      formatBytes,
      deletePopup: false,
      page: 1,
      headers: [
        {
          text: "Icon",
          align: "center",
          sortable: false,
          width: "5%",
          value: "icon"
        },
        {
          // header text
          text: "Platform",
          // align: start | center | end
          align: "center",
          // if you wanted to be sortable
          sortable: false,
          // width: px | %
          width: "5%",
          // related to <template #item.icon="{item}">
          value: "platform"
        },
        {
          text: "Version",
          align: "center",
          sortable: false,
          width: "12%",
          value: "version"
        },
        {
          text: "Package",
          align: "center",
          sortable: false,
          width: "20%",
          value: "package"
        },
        {
          text: "Size",
          align: "center",
          sortable: false,
          width: "10%",
          value: "size"
        },
        // {
        //   text: "Total Installs",
        //   align: "center",
        //   sortable: false,
        //   width: "6%",
        //   value: "meta.total_installs",
        // },
        // {
        //   text: "Total Downloads",
        //   align: "center",
        //   sortable: false,
        //   width: "6%",
        //   value: "meta.total_downloads",
        // },
        {
          text: "Expiry Date",
          align: "center",
          sortable: false,
          width: "19%",
          value: "date"
        },
        {
          text: "Status",
          align: "center",
          sortable: false,
          width: "12%",
          value: "status"
        },
        {
          text: "Action",
          align: "end",
          sortable: false,
          width: "6%",
          value: "action"
        }
      ],
      loading: false,
      skeleton: false
    };
  },
  computed: {
    ...mapGetters({
      releases: "getExpressRelease",
      totalReleases: "getTotalExpressApp",
      totalExpressList: "getTotalExpressList",
      expressReleaseToken: "expressReleaseToken"
    })
  },
  watch: {
    page(val, oldVal) {
      let offset = (val - 1) * 10;
      let limit = val * 10;
      // checked if it is next or previous
      if (val > oldVal) {
        //  if next and all the data are already fetched
        if (this.totalExpressList.length > oldVal * 10) {
          this.setCurrentExpressRelease(
            this.totalExpressList.slice(offset, limit)
          );
        } else {
          // if it is first time, get last id and hit api
          const lastId = this.releases[this.releases.length - 1].id;
          this.getReleases(lastId);
        }
      } else {
        // if it is previous then show already fetched data
        this.setCurrentExpressRelease(
          this.totalExpressList.slice(offset, limit)
        );
      }
    }
  },
  created() {
    if (this.expressReleaseToken) {
      this.subscribeToExpress();
    } else {
      this.getExpressReleaseToken();
    }
    if (!(this.releases && this.releases.length)) {
      this.getReleases();
    } else {
      this.setCurrentExpressRelease(this.totalExpressList.slice(0, 10));
    }
  },
  mounted() {},
  methods: {
    ...mapMutations({
      setCurrentExpressRelease: "setCurrentExpressRelease",
      deductTotalCount: "deductTotalCount",
      setIsSocketDisconnected: "setIsSocketDisconnected"
    }),
    subscribeToExpress() {
      const socketInstance = socket.connect();
      socketInstance.subscribe({
        key: process.env.VUE_APP_EMMITTER_EXPRESS_KEY,
        channel: `main/express/${this.expressReleaseToken}/status`
      });
      socketInstance.on("disconnect", () => {
        this.setIsSocketDisconnected(true);
      });

      socketInstance.on("message", msg => {
        if (
          msg.channel === `main/express/${this.expressReleaseToken}/status/`
        ) {
          let data = msg.asObject();
          this.setExpressStatus(data);
        }
      });
    },
    getExpressReleaseToken() {
      this.$store
        .dispatch(GET_EXPRESS_RELEASE_TOKEN)
        .then(() => {
          this.subscribeToExpress();
        })
        .catch(err => {
          this.notifyErrorMessage({ message: err.message });
        });
    },
    setExpressStatus(data) {
      this.releases.map(release => {
        if (release.id === data.id) {
          release.status = data.status;
          release.url = data.url;
          release.archive_at = data.archive_at;
        }
        return release;
      });
      this.totalExpressList.map(release => {
        if (release.id === data.id) {
          release.status = data.status;
          release.url = data.url;
          release.archive_at = data.archive_at;
        }
        return release;
      });
      this.setCurrentExpressRelease(this.totalExpressList.slice(0, 10));
    },
    async getReleases(lastId) {
      if (this.skeleton) return;
      this.skeleton = true;
      await this.$store
        .dispatch(GET_EXPRESS_LIST, lastId || "")
        .then(() => {
          this.skeleton = false;
        })
        .catch(err => {
          this.notifyErrorMessage({ message: err.message });
          this.skeleton = false;
        });
    },
    onDeleteClick(item) {
      this.selectedId = item.id;
      this.deletePopup = true;
    },
    deleteRelease() {
      this.loading = true;
      this.$store
        .dispatch(REMOVE_EXPRESS_RELEASE, { id: this.selectedId })
        .then(response => {
          if (!this.releases.length && this.page > 1) {
            this.page = this.page - 1;
          }
          this.deductTotalCount();
          this.setCurrentExpressRelease(
            this.totalExpressList.slice((this.page - 1) * 10, this.page * 10)
          );
          this.notifyUserMessage(response.message || "Successfully deleted.");
          this.deletePopup = false;
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          this.notifyErrorMessage(err.message || "Something went wrong");
        });
    }
  }
};
</script>

<style lang="scss" scoped></style>

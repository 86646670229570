<template>
  <div>
    <!-- init tour component -->
    <v-tour name="expressTour" :steps="steps" :callbacks="myCallbacks"></v-tour>

    <!--  -->
    <CreateRelease is-express @success="success" no-header class="mt-10" />
    <!--  -->
  </div>
</template>
<script>
import CreateRelease from "@/view/components/Releases/CreateRelease.vue";
export default {
  components: { CreateRelease },
  data() {
    return {
      myCallbacks: {
        onStop: this.stopTour,
      },
      // vue-tour steps
      steps: [
        {
          target: "#android-box",
          header: {
            title: "Upload Android app",
          },
          content: `Select or drag & drop your .APK file for Android`,
        },
        {
          target: "#ios-box",
          header: {
            title: "Upload iOS app",
          },
          content: `Select or drag & drop your .IPA file for iOS`,
        },
        {
          target: "#release-note-check-box",
          content:
            "You can write one unified release note for both platforms or a note for each separately",
        },
        {
          target: "#android-note",
          content: "Let the users know what to expect from this release",
        },
        {
          target: "#ios-note",
          content:
            "Examples, what did you fix? What to look for? Any special instructions or new features?",
        },
        {
          target: "#terms-check-box",
          content:
            "You agree not to spam or abuse this service or upload malicious files",
        },
        {
          target: "#create-btn",
          content:
            "Once you create, you will have a shareable link to share it with everyone to install it instantly",
        },
      ],
    };
  },
  methods: {
    success(e) {
      this.$emit("success", e);
    },
    stopTour() {
      localStorage.setItem(
        "tour",
        JSON.stringify({
          ...JSON.parse(localStorage.getItem("tour")),
          express_upload: "false",
        })
      );
    },
  },
  async mounted() {
    const tour = await JSON.parse(localStorage.getItem("tour"));
    if (tour && tour.express_upload === "true") {
      this.$tours["expressTour"].start();
    } else if (!tour || !tour.express_upload === "true") {
      localStorage.setItem("express_upload_tour", "true");
      this.$tours["expressTour"].start();
    }
  },
};
</script>

<template>
  <div class="pb-16">
    <!-- <WelcomeModal /> -->
    <Modal
      v-model="installModal"
      :width="info.ipa && info.apk ? 800 : 660"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <template #message>
        <InstallModal
          :platform="installPlatform"
          is-express
          :express-and-both="info.ipa && info.apk"
          :both="forBoth"
          :app-info="{
            releaseId: currentInstallReleaseId,
            version: currentInstallReleaseVersion,
            ...info,
          }"
        />
      </template>
    </Modal>
    <Modal
      v-model="instructionModal"
      :width="660"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <template #message>
        <div v-if="instructionModal">
          <install-intruction-modal is-express />
        </div>
      </template>
    </Modal>
    <v-card
      color="primary"
      flat
      class="d-flex align-center justify-center fill-width"
      height="110"
      tile
    >
      <a href="#" class="text-center pa-5">
        <img
          src="/media/logos/logo_express.png"
          class="max-h-70px"
          alt="TestApp.io Express"
        />
      </a>
    </v-card>
    <v-container class="pt-16">
      <v-tabs v-model="tab">
        <v-tab>About</v-tab>
        <span class="mx-3"></span>
        <v-tab>Upload</v-tab>
        <span class="mx-3"></span>
        <v-tab>Apps</v-tab>
      </v-tabs>
      <v-row justify="space-between">
        <v-col cols="12" md="9">
          <v-tabs v-model="tab" color="primary" class="no-border-tab hide-tabs">
            <v-tabs-items v-model="tab" class="overflow-visible">
              <v-tab-item>
                <v-row no-gutters class="align-center justify-center">
                  <v-col cols="12" lg="10">
                    <About @go-to-upload="tab = 1" />
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <v-card class="mb-3 radius-10" outlined>
                  <v-card-text>
                    <v-row no-gutters class="align-center justify-center">
                      <v-col cols="12" lg="9">
                        <Upload
                          @success="openInstallFromUpload"
                          :key="tab"
                          class="mt-n10"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card class="my-3" flat>
                  <Apps @install="openInstall" class="mt-n3" />
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </v-col>
        <v-col class="mb-15" :cols="getCols()">
          <v-card outlined class="plan-card text-center">
            <div class="top-card mb-15">
              <div class="shape white--text">
                <h2 class="bold-text text-capitalize mb-5">Developer Portal</h2>
                <p>
                  Create team, invite members to test and manage your releases
                </p>
              </div>
              <div class="price-btn">
                <span class="d-flex justify-center align-center">
                  <p class="price-desc" style="border: none">
                    No credit card. It's free.
                  </p>
                </span>
              </div>
            </div>
            <div class="text-left px-8">
              <div
                class="py-1 my-4 bodyFont"
                v-for="(feat, index) in features"
                :key="index"
              >
                <div>
                  <v-icon
                    color="primary"
                    v-text="'mdi-check'"
                    small
                    left
                  ></v-icon>
                  <span class="mr-1" v-html="feat.title"></span>
                  <VueTooltip
                    v-if="feat.info"
                    icon-color="grey"
                    color="white"
                    text-class="primary--text"
                    :v-html="feat.info"
                    :max-width="200"
                  />
                </div>
                <template v-if="feat.subItems && feat.subItems.length">
                  <div
                    v-for="(sub, ind) in feat.subItems"
                    :key="ind"
                    class="my-3 ml-5"
                  >
                    <v-icon
                      color="black"
                      small
                      v-text="'mdi-chevron-right'"
                    ></v-icon>
                    <span class="mr-1" v-html="sub.title"> </span>
                    <VueTooltip
                      v-if="sub.info"
                      icon-color="grey"
                      color="white"
                      text-class="primary--text"
                      :v-html="sub.info"
                      :max-width="200"
                    />
                  </div>
                </template>
              </div>
              <div class="pb-4">
                <v-btn
                  large
                  depressed
                  block
                  class="mx-auto primary text-transform-none mt-7"
                  id="stepTwoNext"
                  @click="redirect"
                >
                  <span class="bold-text"> Sign up, it's free! </span>
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <vue-snackbar />
    </v-container>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/view/layout/footer/Footer.vue";
import InstallModal from "@/view/components/App/InstallModal.vue";
import VueSnackbar from "@/view/components/Common/VueSnackbar";
import Upload from "@/view/pages/express/upload.vue";
import Apps from "@/view/pages/express/apps.vue";
import About from "@/view/pages/express/about.vue";
import {
  GET_EXPRESS_LIST,
  GET_EXPRESS_RELEASE_TOKEN,
} from "@/store/releases/expressRelease.module.js";
import { mapGetters, mapMutations } from "vuex";
import StorageService from "@/core/services/localstorage.service";
import InstallIntructionModal from "@/view/components/Releases/InstallIntructionModal";

export default {
  components: {
    InstallIntructionModal,
    VueSnackbar,
    Upload,
    Apps,
    InstallModal,
    Footer,
    About,
  },
  metaInfo: {
    link: [{ rel: "canonical", href: "https://portal.testapp.io/express" }],
    title: "TestApp.io - Portal Express ",
    meta: [
      {
        name: "description",
        content: `With TestApp.io Express, you can share your app in testing on our platform with everyone without having to sign up. Check it out today!`,
      },
      {
        name: "og:description",
        content: `With TestApp.io Express, you can share your app in testing on our platform with everyone without having to sign up. Check it out today!`,
      },
      // {
      //   name: "robots",
      //   content: `noindex,nofollow`
      // },
      {
        name: "twitter:description",
        content: `With TestApp.io Express, you can share your app in testing on our platform with everyone without having to sign up. Check it out today!`,
      },
    ],
  },
  data() {
    return {
      tab: 1,
      installModal: false,
      installPlatform: null,
      instructionModal: false,
      currentInstallReleaseId: "",
      currentInstallReleaseVersion: "",
      forBoth: false,

      features: [
        {
          title: "<strong>Manage</strong> app projects",
        },
        {
          title: "<strong>Manage</strong> releases",
        },
        {
          title: "<strong>Monitor</strong> installs",
        },
        {
          title: "<strong>Manage</strong> testers/members",
        },
        {
          title: "Install apps from <strong>Mobile App</strong>",
        },
        {
          title: "Bigger file <strong>size</strong>",
        },
        {
          title: "Share: <strong>Private & Public</strong>",
        },
        {
          title: "<strong>Chat</strong> to collect feedback",
        },
        {
          title: "Integrations",
        },
      ],
      info: {},
      copyUrl: "",
      toggle: false,
    };
  },
  TABS: ["about", "upload", "express-apps"],
  computed: {
    ...mapGetters({
      releases: "getExpressRelease",
      totalExpressList: "getTotalExpressList",
      expressReleaseToken: "expressReleaseToken",
      isAuthenticated: "isAuthenticated",
    }),
  },
  watch: {
    tab(tab) {
      if (this.$route.name === this.$options.TABS[tab || 0]) {
        return;
      }
      this.$router
        .push({
          name: this.$options.TABS[tab || 0],
        })
        .catch((err) => {
          this.notifyErrorMessage(
            err.message || "Something went wrong, please try again"
          );
        });
    },
  },
  created() {
    if (!this.expressReleaseToken) {
      this.getExpressReleaseKey();
    }
    this.tab = this.$options.TABS.indexOf(this.$route.name);
  },
  methods: {
    ...mapMutations({
      setExpressHeaderOption: "setExpressHeaderOption",
    }),
    getCols() {
      if (
        this.$vuetify.breakpoint.mdAndDown &&
        this.$vuetify.breakpoint.smAndUp
      ) {
        return 6;
      } else if (this.$vuetify.breakpoint.mdAndUp) {
        return 3;
      } else if (this.$vuetify.breakpoint.smAndDown) {
        return 12;
      } else {
        return 12;
      }
    },
    redirect() {
      this.$router
        .push({ name: this.isAuthenticated ? "dashboard" : "signup" })
        .catch();
    },
    getExpressReleaseKey() {
      this.$store
        .dispatch(GET_EXPRESS_RELEASE_TOKEN)
        .then((response) => {
          this.setExpressHeaderOption(response.token);
        })
        .catch((err) => {
          this.notifyErrorMessage({ message: err.message });
        });
    },
    openInstall(release) {
      if (release) {
        if (release.both) {
          this.forBoth = release.both;
          this.info = release;
          this.installPlatform = "";
        } else {
          this.info = release.info;
          this.installPlatform = release.platform || "";
          this.currentInstallReleaseId = release.expReleaseID || release.id;
          this.currentInstallReleaseVersion =
            release.info && release.info.version
              ? `v${release.info.version} (${release.info.version_code})`
              : "";
          this.forBoth = false;
        }
      } else {
        this.installPlatform = "";
        this.currentInstallReleaseId = "";
        this.currentInstallReleaseVersion = "";
        this.forBoth = true;
        this.fromAppInfo = true;
      }
      this.installModal = !this.installModal;
    },
    setReleaseImage(details) {
      this.releases.map((release) => {
        if (details.expReleaseID) {
          if (release.id === details.expReleaseID) {
            release.image = details.info.icon;
          }
        } else {
          if (release.id === details.androidId) {
            release.image = details.apk.info.icon;
          }
          if (release.id === details.iosId) {
            release.image = details.ipa.info.icon;
          }
        }
        return release;
      });
      this.totalExpressList.map((release) => {
        if (details.expReleaseID) {
          if (release.id === details.expReleaseID) {
            release.image = details.info.icon;
          }
        } else {
          if (release.id === details.androidId) {
            release.image = details.apk.info.icon;
          }
          if (release.id === details.iosId) {
            release.image = details.ipa.info.icon;
          }
        }
        return release;
      });
    },
    toggler(n) {
      n === "Your Storage" ? (this.toggle = !this.toggle) : null;
    },
    showInstallInstruction() {
      const popup = StorageService.getItem("popup");
      if (!popup || !popup.install_instruction) {
        this.instructionModal = true;
        StorageService.setItem("popup", { install_instruction: "false" });
      } else if (popup && popup.install_instruction === "false") {
        this.instructionModal = true;
      }
    },
    async openInstallFromUpload(e) {
      await this.showInstallInstruction();
      await this.$store.dispatch(GET_EXPRESS_LIST).catch((err) => {
        this.notifyErrorMessage({ message: err.message });
      });
      this.setReleaseImage(e);
      this.tab = 2;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";

.blue-bordered-light {
  background-color: rgba(blue, 0.13) !important;
  padding: 6px 11px;
  border: 1px solid rgba(blue, 0.25);
  color: blue !important;
}
</style>

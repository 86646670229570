var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.skeleton)?_c('v-skeleton-loader',{staticClass:"transparent",attrs:{"type":"table-thead, table-tbody"}}):_c('v-data-table',{staticClass:"v-card v-sheet v-sheet--outlined radius-10 release-table hide-v-data-footer__select",attrs:{"headers":_vm.headers,"items":_vm.releases,"items-per-page":10,"page":_vm.page,"server-items-length":_vm.totalReleases,"mobile-breakpoint":"550"},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center my-2"},[_c('AppImage',{staticClass:"d-flex justify-center align-center",attrs:{"appId":item.id || '',"image":item.image,"size":"40"}})],1)]}},{key:"item.platform",fn:function(ref){
var item = ref.item;
return [(item.platform == 'android')?_c('PlatformIcon',{attrs:{"android":"","size":"32","color":"primary"}}):_vm._e(),(item.platform == 'ios')?_c('PlatformIcon',{attrs:{"apple":"","size":"32","color":"primary"}}):_vm._e()]}},{key:"item.version",fn:function(ref){
var item = ref.item;
return [_c('MaxText',{attrs:{"text":("v" + (item.info.version) + " (" + (item.info.version_code) + ")"),"max":"20"}})]}},{key:"item.package",fn:function(ref){
var item = ref.item;
return [_c('MaxText',{attrs:{"text":item.info.package,"max":"26"}})]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatBytes(item.info.size))+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [(item.archive_at)?_c('vue-hoverable-date',{attrs:{"is-expired":item.status === 3,"date":item.archive_at}}):_c('div',[_vm._v("-")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.$options.STATUS[item.status].color + '--text'},[_vm._v(" "+_vm._s(_vm.$options.STATUS[item.status].title)+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [([4, 5].includes(item.status))?_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-minus")]):_c('action-dropdown',{scopedSlots:_vm._u([{key:"list",fn:function(){return [(item.status == 1)?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('install', item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-cellphone-iphone")]),_vm._v(" Install ")],1):_vm._e(),(item.status !== 4 && item.status !== 5)?_c('v-list-item',{on:{"click":function($event){return _vm.onDeleteClick(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("cancel")]),_vm._v(" Delete ")],1):_vm._e()]},proxy:true}],null,true)})]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"no-data-found mt-6"},[_c('inline-svg',{staticClass:"svg-icon empty_icon",attrs:{"src":"/media/placeholders/no_apps.svg"}}),_c('p',{staticClass:"mt-4 empty-title font-weight-bold text-dark text-center"},[_vm._v(" No active apps ")]),_c('p',{staticClass:"mt-4 empty-title font-weight-bold text-dark text-center"},[_vm._v(" This list is only visible to you but you can share the apps not the whole list. ")])],1)]},proxy:true}])}),_c('Modal',{scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('v-card-title',{staticClass:"font-weight-bold"},[_vm._v("Delete release")]),_c('v-card-text',{staticClass:"message"},[_c('p',[_vm._v("Are you sure you want to delete this release?")]),_c('p',[_vm._v("There's no undo for this action.")])]),_c('v-divider'),_c('v-card-actions',{staticClass:"pb-0"},[_c('v-spacer'),_c('v-btn',{staticClass:"main",on:{"click":function($event){_vm.deletePopup = false}}},[_vm._v("no")]),_c('v-btn',{staticClass:"main primary",attrs:{"loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.deleteRelease}},[_vm._v(" yes ")])],1)]},proxy:true}]),model:{value:(_vm.deletePopup),callback:function ($$v) {_vm.deletePopup=$$v},expression:"deletePopup"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
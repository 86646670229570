<template>
  <v-container class="pa-0">
    <v-card class="radius-10 font-size-h4 py-7 px-4 pa-lg-7 text-center" flat>
      <v-card-text class="title">
        Express Upload
        <br />
        <small class="text-muted">No account is required</small>
        <br /><br />

        TestApp.io is the fastest way to share your app with everyone.
        <br />
        We made it even more faster for you to share your app without
        <span class="primary--text text-decoration-line-through">signup</span>
      </v-card-text>
    </v-card>
    <v-row justify="center" class="mt-7 px-lg-13">
      <v-col cols="6" sm="5" md="4">
        <v-card class="pb-6 pt-4 px-8 text-center radius-10" flat height="250">
          <h1 class="darkGrey--text">1</h1>
          <h3 class="primary--text">Upload</h3>
          <img height="140" src="/media/svg/upload.svg" class="mt-3" />
        </v-card>
      </v-col>
      <v-col cols="6" sm="5" md="4">
        <v-card class="pb-6 pt-4 px-8 text-center radius-10" flat height="250">
          <h1 class="darkGrey--text">2</h1>
          <h3 class="primary--text">Share</h3>
          <img height="140" src="/media/svg/install.svg" class="mt-3" />
        </v-card>
      </v-col>
      <v-col cols="6" sm="5" md="4">
        <v-card class="pb-6 pt-4 px-8 text-center radius-10" flat height="250">
          <h1 class="darkGrey--text">3</h1>
          <h3 class="primary--text">Relax</h3>
          <img height="125" src="/media/svg/relax.png" class="mt-3" />
        </v-card>
      </v-col>
    </v-row>
    <h2 class="font-weight-regular primary--text text-center mt-6 mb-9">
      Happy testing 🎉
    </h2>
    <v-btn
      class="main primary big wide normal-text mx-auto"
      @click="$emit('go-to-upload')"
      >Let's start</v-btn
    >
    <div class="mt-13 text-center">
      <p class="mb-1">
        * Limited time feature to show our support for all developers.
      </p>
      <span
        class="text-decoration-underline primary--text pointer"
        @click="modal = !modal"
      >
        Learn more about the Express limitations and terms of service.
      </span>
    </div>
    <Modal v-model="modal">
      <template #message>
        <div class="my-n2">
          <!-- add your content here -->
          <!-- for the title -->
          <v-card-title class="font-weight-bold"
            >TestApp.io Express</v-card-title
          >
          <v-divider />

          <!-- for the body -->
          <v-card-text class="message">
            <p>
              We created this feature to allow you to try our app distribution
              without creating an account and the same applies for the ones who
              will install your apps!
            </p>
            <p>There are couple of limitations:</p>
            <ul>
              <li>Maximum upload size <b>50 MB</b> per app</li>
              <li>Apps will be archived after <b>3 days</b> of creation</li>
              <li>Installations per app <b>20</b> installs/downloads</li>
            </ul>

            <br />
            <p>
              This is a limited time feature and if you want to continue using
              this service and more, you can simply
              <a href="/signup">signup for free</a>.
            </p>

            <vue-inline-alert
              message-type="info"
              no-close-btn
              message="Please note that we will be automatically blocking all spam and malicious
              requests and in some cases your IP address permanently in case of
              any misuse."
            />

            <p>
              For more info, please read our
              <a href="https://testapp.io/terms-and-conditions" target="_blank"
                >Terms & Conditions</a
              >
              and
              <a href="https://testapp.io/privacy-policy" target="_blank"
                >Privacy Policy</a
              >
              or contact us for more info.
            </p>

            <p>Happy testing!</p>
          </v-card-text>
          <v-divider />

          <!-- and for the actions, submit... -->
          <v-card-actions>
            <v-spacer />
            <v-btn class="main primary" @click="modal = false">close</v-btn>
          </v-card-actions>
        </div>
      </template>
    </Modal>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      modal: false
    };
  }
};
</script>

<style lang="scss" scoped></style>
